<template>
  <div class="dealer">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchCustomer()"
          v-model="customerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchCustomer()"
          @keyup.enter.native="searchCustomer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="customerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      isLoading: false,
      entries: [],
      search: null,
      customerSearch: "",
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Email", value: "email" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "mobilePhone" },
      ],
      customerList: [],
    };
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?searchContent=${val}`
      )
        .then(res => {
          this.entries = res.data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchCustomerList();
  },
  methods: {
    searchCustomer() {
      this.fetchCustomerList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCustomerList();
    },
    fetchCustomerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.customerSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/customerData`,
        params
      ).then(res => {
        if (res.data) {
          self.customerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
  },
};
</script>
